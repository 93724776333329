'use client';

import { Toaster } from 'react-hot-toast';

const ToastContainer = () => {
  return (
    <Toaster
      position="top-center"
      reverseOrder={false}
      gutter={8}
      containerStyle={{
        top: 61,
      }}
      toastOptions={{
        // デフォルトのオプションをここで設定
        duration: 3000,
        style: {
          background: '#fff',
          color: '#333',
          border: '2px solid #FFCEE0',
        },
        success: {
          iconTheme: {
            primary: '#F74481',
            secondary: '#fff',
          },
        },
      }}
    />
  );
};

export default ToastContainer;
