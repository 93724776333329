export const pagePath = {
  // TOP
  top: '/account/top',
  // ログイン画面
  login: '/login',
  // パスワード再設定	メールアドレス入力
  passwordResetInputMail: '/password-reset/input-mail',
  // パスワードリセットメール送信
  passwordResetMailSend: '/password-reset/mail-send',
  // パスワード設定
  passwordResetInputPassword: '/password-reset/input-password',
  // パスワードリセット完了
  passwordResetComplete: '/password-reset/complete',
  // メールアドレス再設定
  MailResetInputMail: '/mail-reset/input-mail',
  // メールアドレス変更 メールアドレス入力
  updateEmailInputEmail: '/update-email/input-email',
  // メールアドレス変更 メール送信
  updateEmailMailSend: '/update-email/mail-send',
  // メールアドレス変更 完了
  updateEmailComplete: '/update-email/complete',
  // 新規登録	メールアドレス入力
  register: '/register',
  // メール認証
  mailAuth: '/register/mail-auth',
  // アカウント登録完了
  registerComplete: '/register/complete',

  signUpResume: '/profile/register/resume',
  // ユーザー名
  profileUsername: '/profile/register/username',
  // 性別選択
  profileSex: '/profile/register/gender',
  // 登録写真のポイント
  profileImageRecommend: '/profile/register/image-recommend',
  // 写真登録
  profileImageInput: '/profile/register/image-input',
  // 年齢確認（ファイル選択）
  profileAgeInput: '/profile/register/age-input',
  // 年齢確認ファイル提出
  profileAgeConfirm: '/profile/register/age-confirm',
  // 提出完了
  profileAgeComplete: '/profile/register/age-complete',
  // アンケート
  profileEnquete: '/profile/register/enquete',
  // プロフィール登録完了
  profileComplete: '/profile/register/profile-complete',
  // 退会	プラン決済のキャンセル
  deleteEnquetInput: '/account/delete/enquet-input',
  // キャンセル希望画面
  deleteEnquetComplete: '/account/delete/enquet-complete',
  // 退会希望
  deleteRequest: '/account/delete/request',
  // 退会手続き完了
  deleteComplete: '/delete/complete',
  // 退会手続き決済
  deletePayment: '/account/delete/payment',
  // プラン選択
  paymentMaleSelectPlan: '/account/payment/male/select-plan',
  // 決済画面
  paymentMaleInput: '/account/payment/male/input',
  // 決済完了
  paymentMaleComplete: '/account/payment/male/complete',
  // プレミアムプラン選択
  paymentFemaleSelectPlan: '/account/payment/female/select-plan',
  // 決済画面
  paymentFemaleInput: '/account/payment/female/input',
  // 決済完了
  paymentFemaleComplete: '/account/payment/female/complete',
  // 決済変更	決済登録
  payment: '/account/payment',
  // カード情報変更
  paymentCardChange: '/account/payment/card-change',
  // いいね購入	個数選択
  paymentFavoriteSelect: '/account/payment/favorite/select',
  // 決済画面
  paymentFavoriteInput: '/account/payment/favorite/input',
  // 決済完了
  paymentFavoriteComplete: '/account/payment/favorite/complete',

  nomatching: '/account/nomatching',
  noMatchReasonComplete: '/account/nomatching/complete',
  // チャット
  chat: '/account/chat',
  // チャット個別
  chatRoom: '/account/chat/room',
  // チャット運営（運営とメッセージ？）
  chatRoomAdmin: '/chat/room/XX',

  // ユーザー情報
  userProfile: '/account/user',

  profile: '/account/profile',
  // ユーザー情報編集
  profileEdit: '/account/profile/edit',

  // パスワード更新
  passwordUpdate: '/account/password-update/input-passwords',
  // パスワード更新完了
  passwordUpdateComplete: '/account/password-update/complete',

  // マイページTOP
  mypage: '/account/mypage',

  // meta
  // 利用規約
  termsFemale: 'https://service.seiheki-matching.jp/WOMEN_userpolicy',
  termsMale: 'https://service.seiheki-matching.jp/MEN_userpolicy',

  // 悪質行為への行動指針について
  guideLineFemale: 'https://service.seiheki-matching.jp/WOMEN_guideline',
  guideLineMale: 'https://service.seiheki-matching.jp/MEN_guideline',

  // 不正行為への取り組みについて
  cheatingFemale: 'https://service.seiheki-matching.jp/WOMEN_cheating',
  cheatingMale: 'https://service.seiheki-matching.jp/MEN_cheating',

  // プライバシーポリシー
  privacyPolicyFemale: 'https://service.seiheki-matching.jp/WOMEN_policy',
  privacyPolicyMale: 'https://service.seiheki-matching.jp/MEN_policy',

  // 特定商取引法に基づく表記
  lawFemale: 'https://service.seiheki-matching.jp/WOMEN_tokutei',
  lawMale: 'https://service.seiheki-matching.jp/MEN_tokutei',

  // 運営会社
  company: 'https://itonami.co.jp/',

  // 不明
  // あしあと
  footprint: '/account/footprint',
  // みんなのレビュー
  review: '/account/review',
  // ボード
  board: '/account/board',
  // お知らせ一覧
  news: '/account/news',
  // 女性コミュニティ
  femaleCommunity: 'https://x.com/i/communities/1498666591294488578',
  // 性癖マッチンコラム
  column: 'https://media.seiheki-matching.jp/',

  // マッチング画面
  matching: '/account/matching',

  account: {
    top: '/account/top',
    // もらったいいね
    favored: '/account/user/category/favored?action=LIKED_BY&page=1',
    // 送ったいいね
    favoriteCard: '/account/user/category/favored?action=LIKED&page=1',
    // マッチしている人
    matched: '/account/user/category/matched?action=MATCHED&page=1',
    // レコメンドされた方（女性のみ）
    recommend: '/account/user/category/matched?action=RECOMMENDED&page=1',
    // ノーマッチンした男性一覧（女性プレミアムのみ）
    noMatchinMaleList:
      '/account/user/category/nomatched?action=NOMATCHED&page=1',
    // 相性のいいユーザー
    compatible: '/account/user/category/compatible?page=1',
    nearby: '/account/user/category/nearby?page=1',
    popular: '/account/user/category/popular?page=1',
    'highly-responsive': '/account/user/category/highly-responsive?page=1',
    'recently-joined': '/account/user/category/recently-joined?page=1',
    // カテゴリ
    category: '/account/user/category/',
  },
};
