'use client';

import Image from 'next/image';
import Link from 'next/link';

import { pagePath } from '@/constants/pagePath';

import module from './HeaderSimple.module.css';

export const HeaderSimple = () => {
  const getTopPageLink = () => {
    return pagePath.top;
  };
  const topPageLink = getTopPageLink();
  return (
    <header className="fixed left-0 top-0 z-50 w-full bg-white">
      <div className={module.header__wrap}>
        <div className="flex items-center justify-center">
          <Link href={topPageLink}>
            <Image
              src="/assets/images/logo.png"
              alt="性癖マッチン"
              width={169}
              height={30}
            />
          </Link>
        </div>
      </div>
    </header>
  );
};
